'use client'

import { cn } from '@/lib/utils/utils'
import { useMounted } from '@/lib/utils/utils-client'
import { useEffect, useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { getLangCode, I18nDictionary, I18nValue, useI18n, useI18nValue } from '../i18n/lang-context'
import MD from '../markdown'
import { Flex, FlexCol } from '../ui/flex'
import { Section } from './section'

const I18N: I18nDictionary = {
  title: { en: 'FAQ', fr: 'FAQ' }
}

export type Question = {
  question: I18nValue
  answer: I18nValue
}

export function Question({ question, index, opened, setOpened, background2 }: { question: Question, index: number, opened: boolean, setOpened: (i: number) => void, background2?: boolean }) {

  const i18nValue = useI18nValue()

  const [_opened, set_Opened] = useState<boolean>(true)

  const mounted = useMounted()
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Access the anchor using window.location.hash
      const anchor = window.location.hash
      // If access to a direct FAQ line is requested, answer will be unfolded
      if(anchor === '#QUESTION_LINE_'+index || anchor === '#QUESTION_'+index ||
         anchor === '#ARROW_'+index || anchor === '#ANSWER_'+index)
        opened = true
    }
    if (mounted) { set_Opened(opened); return }
  }, [opened, mounted])

  return (
    <FlexCol className='w-full transition-all' key={index}>
      <Flex id={'QUESTION_LINE_'+index} className={cn('w-full cursor-pointer flex-nowrap items-center justify-between gap-2 rounded-md p-2 px-3 text-lg transition-all hover:opacity-100 sm:gap-4 sm:p-3 sm:px-4', opened ? (background2 ? 'bg-background' : 'bg-background2') : 'opacity-80', background2 ? 'hover:bg-background' : 'hover:bg-background2')} onClick={() => setOpened(opened ? -1 : index)}>
        <div id={'QUESTION_'+index} className='select-none text-[15px] font-medium sm:text-lg'>{i18nValue(question.question)}</div>
        <div id={'ARROW_'+index} className='text-lg font-semibold opacity-70'>
          <MdKeyboardArrowDown size={24} className={cn('transition-all', _opened ? 'rotate-180' : '')} />
        </div>
      </Flex>
      <div id={'ANSWER_'+index} className={cn('mt-2 w-full rounded-lg p-2 px-3 text-[14px] transition-all duration-300 sm:mt-3 sm:p-3 sm:px-4 sm:text-base', _opened ? '' : 'z-0 my-0 h-0 py-0 opacity-0 sm:my-0 sm:py-0', background2 ? 'bg-background' : 'bg-background2')}>
        <Paragraphs input={i18nValue(question.answer)} className={cn(_opened ? '' : 'hidden')} />
      </div>
    </FlexCol>
  )
}

export function FAQ({ questions, background2 }: { questions: Question[], background2?: boolean }) {

  const i18n = useI18n(I18N)
  const [opened, setOpened] = useState<number>(-1)

  return (
    <Section id='FAQ' containerClassName={cn(background2 ? 'bg-background2' : 'bg-background')}>

      <SectionTitle title={i18n.title} desc={i18n.desc} langCode={getLangCode()} />

      <FlexCol id='QUESTIONS' className='w-full gap-2 sm:gap-3'>
        {questions.map((question, i) => <Question question={question} opened={opened === i} setOpened={setOpened} index={i} key={i} background2={background2} />)}
      </FlexCol>

    </Section>
  )
}

function SectionTitle({ title, desc, langCode }: { title: string, desc: string, langCode: string }) {

  return (
    <div id='SECTION_TITLE' className={cn('flex w-full flex-col items-center gap-8 pt-4')}>
      <h2 id='SUB_TITLE' className={cn('flex max-w-3xl items-start gap-1 text-center text-2xl font-bold sm:text-3xl')}>
        <MD textAlign='center'>{title}</MD>
        <span className='text-xs font-extralight text-primary opacity-50 dark:text-primaryBright'>{langCode}</span>
      </h2>
      <div id='DESC' className={cn('max-w-3xl text-center sm:text-lg')}>
        <MD textAlign='center'>{desc}</MD>
      </div>
    </div>
  )
}

function Paragraphs({ input, className = '' }: { input: String, className?: string }) {
  return <div>
    {input.split('\n').map((p, i) => (
      <div key={i} className={className}>
        {p}<br />
      </div>
    )
    )}
  </div>
}
